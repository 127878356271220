// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$shiftster-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($shiftster-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($shiftster-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($shiftster-theme);

@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alata&family=League+Spartan:wght@100..900&display=swap');

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Alata", sans-serif;
  overflow: hidden;
}

.disable-overflow {
  overflow: hidden !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: unset !important;
}

.mat-mdc-button .mat-mdc-button-persistent-ripple {
  border-radius: unset !important;
}

.container {
  width: 100%;
  height: 100%;
  background-color: #161616;

}

.list-container {
  height: 77vh;
  mat-list-item {
    border: 1px solid #ff6600;
    padding-right: 0;
    padding-left: 0;
  }

  .item-text{
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 2rem;
    margin-top: 10px;
  }

  .empty-slot{
    width: 64px;
  }
}

h3 {
  color: white;
  border: 1px solid #ff6600;
  border-bottom: 3px solid #ff6600;;
  padding: 10px;
  margin: 0;
  font-size: 1.3rem;
  font-weight: normal;
}

.goto-button{
  width: 100px;
  height: 20px;
  background: #ff6600;
  padding: 5px;
  text-decoration: none;
  text-align: center;
  display: block;
  color: #161616;
  border-style: solid;
  border-width: medium;
  border-radius: 10px;
  border-color: #f60;
  font-size: 1rem;
}

.mdc-list-item__content {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.mdc-list-item__primary-text {
  color: white !important;
  height: 60px;
  display: flex;
  align-items: center;
  font-family: "League Spartan", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 2.4rem !important;
  font-weight: bold !important;
}
